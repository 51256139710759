import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

interface ProjectProps {
  data: {
    markdownRemark: {
      html: string
      frontmatter: {
        title: string
        history: string
      }
    }
  }
}
const Project: React.FC<ProjectProps> = props => {
  const {
    data: {
      markdownRemark: {
        html,
        frontmatter: { title, history },
      },
    },
  } = props

  return (
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>
      <div dangerouslySetInnerHTML={{ __html: html }} />
      <h2>History</h2>
      <p>{history}</p>
    </Layout>
  )
}

export default Project

export const projectQuery = graphql`
  query Project($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        history
      }
    }
  }
`
